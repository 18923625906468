import { OrgPerson } from '../../context/org-person';
import { formatDate } from '../../utils/format-date';
import { Section } from '../section';

export const DocumentSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const identityDocumentExpirationDate = orgPerson.personMetadata.dates?.identityDocumentExpirationDate;
	const releaseDate = orgPerson.personMetadata.identityDocument?.releaseDate;
	return (
		<>
			{/* <Section title={'Tipo documento'}>{orgPerson.personMetadata.identityDocument?.type || '-'}</Section> */}
			<Section title={'Numero documento'}>{orgPerson.personMetadata.identityDocument?.number || '-'}</Section>

			<Section title="Scadenza documento">
				{identityDocumentExpirationDate ? formatDate(identityDocumentExpirationDate) : '-'}
			</Section>

			<Section title={'Data rilascio documento'}>{releaseDate ? formatDate(releaseDate) : '-'}</Section>
		</>
	);
};
