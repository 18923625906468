import { Button } from '@/components/button';
import { useI18n } from '@gle/providers.i18n';
import { FormikContextType } from 'formik';
import { EditIcon } from 'lucide-react';

export type SubmitButtonsProps = {
	isSaving?: boolean;
	formContext?: Pick<FormikContextType<any>, 'dirty' | 'resetForm'>;
	isEditing: boolean;
	onChangeEdit?: (value: boolean) => void;
	onSubmit?: () => void;
};
export const SubmitButtons = (props: SubmitButtonsProps) => {
	const { messages } = useI18n();

	return (
		<>
			{!props.isEditing ? (
				<div className="-mt-5 flex justify-end">
					<Button variant={'secondary'} onClick={() => props.onChangeEdit?.(true)}>
						<EditIcon size={18} className="mr-2" />
						{messages['edit']}
					</Button>
				</div>
			) : (
				<div className="-mt-5 flex justify-end gap-2">
					<Button
						variant="secondary"
						disabled={props.isSaving}
						onClick={() => {
							props.onChangeEdit?.(false);
							props.formContext?.resetForm();
						}}>
						{messages['cancel']}
					</Button>
					<Button type="submit" isLoading={props.isSaving} onClick={() => props.onSubmit?.()}>
						{messages['save']}
					</Button>
				</div>
			)}
		</>
	);
};
