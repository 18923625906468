import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Content } from './content';
import { Header } from './header';

export const MenuLayout = () => {
	return (
		<div className={'h-[100vh]'}>
			<Header hideNavigation={false} />
			<Content>
				<Outlet />
			</Content>
		</div>
	);
};

export const LayoutBase = (props: { children: ReactNode }) => {
	return (
		<div>
			<div>
				<Header hideNavigation />
				<Content>{props.children}</Content>
			</div>
		</div>
	);
};
