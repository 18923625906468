import { useI18n } from '@gle/providers.i18n';
import { FormikContextType } from 'formik';
import { Metadata } from '../../api/people/metadata';
import { CustomField } from '../../hooks/custom-input-fields/types';
import { Section } from '../section';
import { CustomInputField } from './custom-input-field';
import { CustomValueField } from './custom-value-field';

export const CustomInputFields = (props: {
	fields: CustomField[];
	formContext: FormikContextType<Metadata>;
	isEditing: boolean;
}) => {
	const { messages } = useI18n();

	return (
		<>
			{props.fields.map(field => {
				//TODO: smell. We have to remove metadata. cause we are calling PATCH on metadata ad it is included
				const fieldKey = field.key.replace('metadata.', '');
				return (
					<Section title={messages[field.labelKey]}>
						{field.isEditableFromOrgPerson && props.isEditing ? (
							<CustomInputField
								hideLabel
								field={{ ...field }}
								value={getValueByDotNotation(props.formContext.values, fieldKey)}
								onChange={value => {
									props.formContext.setFieldValue(fieldKey, value);
								}}
							/>
						) : (
							<CustomValueField
								field={field}
								value={getValueByDotNotation(props.formContext.values, fieldKey)}
							/>
						)}
					</Section>
				);
			})}
		</>
	);
};

const getValueByDotNotation = (obj: any, dotNotation: string): any => {
	return dotNotation.split('.').reduce((acc, key) => acc?.[key], obj);
};
