import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { ChevronLeftIcon } from 'lucide-react';
import React, { createContext, useEffect, useState } from 'react';
import { LayoutBase } from '../../components/layout/layout';
import { useProfiles } from '../profiles/use-profiles';
import { useSeason } from '../season';
import { getOrganization, loadOrgPerson, loadOrgPersonParents } from './api';
import { Organization, OrgPerson } from './types';

type OrgPersonContextType = {
	orgPerson: OrgPerson;
	organization: Organization;
	refresh: () => void;
};

export const OrgPersonContext = createContext<OrgPersonContextType | undefined>(undefined);

export const OrgPersonProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const { currentSeason } = useSeason();
	const { selectedProfile, unselectProfile } = useProfiles();

	const [organization, setOrganization] = useState<Organization | undefined>();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [orgPerson, setOrgPerson] = useState<OrgPerson>();

	useEffect(() => {
		void init({ loadOrganization: true, loadParents: true });
	}, []);

	const lazyLoadParents = async (orgPersonId: string) => {
		try {
			const { data: parents } = await loadOrgPersonParents(orgPersonId, currentSeason);
			if (parents.length) {
				setOrgPerson(current => {
					if (!current) return current;
					return {
						...current,
						parents: parents,
					};
				});
			}
		} catch (err) {}
	};

	//SMELL
	const init = async (config?: { loadOrganization?: boolean; loadParents?: boolean }) => {
		if (!selectedProfile) return;

		setIsLoading(true);

		try {
			if (config?.loadOrganization) {
				const { data } = await getOrganization(selectedProfile.ref_organization);
				setOrganization(data);
			}

			const { data } = await loadOrgPerson(currentSeason);
			setOrgPerson(data);

			if (config?.loadParents) {
				lazyLoadParents(data.orgPersonId);
			}
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	const refresh = async () => {
		void init();
	};

	if (isLoading && !orgPerson) {
		return <>{props.fallback}</>;
	}

	if (!orgPerson || !organization) {
		return (
			<LayoutBase>
				<Heading level={2}>Si è verificato un errore</Heading>
				<p className="text-slate-600">
					Il profilo associato a questo tesserato è stato eliminato. Contatta la tua società per maggiori
					informazioni
				</p>

				<div className="mt-5 flex justify-center">
					<Button variant={'secondary'} onClick={unselectProfile}>
						<ChevronLeftIcon size={18} className="mr-2" />
						{'Indietro'}
					</Button>
				</div>
			</LayoutBase>
		);
	}

	return (
		<OrgPersonContext.Provider
			value={{
				orgPerson,
				refresh,
				organization,
			}}>
			{props.children}
		</OrgPersonContext.Provider>
	);
};
