import { useState } from 'react';
import { useOrgPerson } from '../../../../../context/org-person/use-org-person';
import { Section } from '../../../section';
import { TabProps } from '../types';
import { GeneralForm } from './general/form';
import { GeneralView } from './general/view';
import { RenameOrgPersonForm } from './rename-org-person/form';
import { RenameOrgPersonView } from './rename-org-person/view';

export const GeneralTab = (props: TabProps & { isRenaming: boolean; onChangeRenaming: (value: boolean) => void }) => {
	const { orgPerson, refresh } = useOrgPerson();

	const [nameSurname, setNameSurname] = useState<{ name: string; surname: string }>({
		name: orgPerson?.name || '',
		surname: orgPerson?.surname || '',
	});

	return (
		<div className="flex flex-col gap-4">
			<Section
				headerText={'Dati Principali'}
				isEditing={props.isRenaming}
				viewSection={
					<RenameOrgPersonView
						canEdit={props.canEdit}
						name={nameSurname.name || orgPerson.name}
						surname={nameSurname.surname || orgPerson.surname}
						avatarUrl={orgPerson.personMetadata.avatar}
						isEditing={props.isEditing}
						isRenaming={props.isRenaming}
						onChangeRenaming={(value: boolean) => {
							props.onChangeRenaming(value);
						}}
					/>
				}
				formSection={
					<RenameOrgPersonForm
						orgPerson={orgPerson}
						onChangeRenaming={(value: boolean) => {
							props.onChangeRenaming(value);
						}}
						onSave={(orgPerson: { name: string; surname: string }) => {
							setNameSurname(orgPerson);
						}}
					/>
				}
			/>

			<Section
				isEditing={props.isEditing}
				headerText="Dati generali"
				viewSection={
					<GeneralView
						canEdit={props.canEdit}
						orgPerson={orgPerson}
						isEditing={props.isEditing}
						isRenaming={props.isRenaming}
						onChangeEdit={(value: boolean) => {
							props.onChangeEdit(value);
						}}
					/>
				}
				formSection={
					<GeneralForm
						orgPerson={orgPerson}
						isEditing={props.isEditing}
						onSave={refresh}
						onChangeEdit={(value: boolean) => props.onChangeEdit(value)}
					/>
				}
			/>
		</div>
	);
};
