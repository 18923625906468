import { ChevronLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const NavigateBack = ({ color = 'var(--white-color)', ...props }: { onClick?: () => void; color?: string }) => {
	const navigate = useNavigate();

	return (
		<p
			onClick={() => navigate(-1)}
			className="mb-1 flex cursor-pointer items-center gap-1 text-sm font-medium text-slate-500 hover:underline">
			<ChevronLeftIcon size={18} />
			Indietro
		</p>
	);
};
