import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { EventList } from './list/event-list';

export const Calendar = () => {
	const { orgPerson } = useOrgPerson();

	if (!orgPerson) {
		return <></>;
	}

	return <EventList orgPersonId={orgPerson.orgPersonId} organizationId={orgPerson.organizationId} />;
};
