import { useI18n } from '@gle/providers.i18n';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

export type Range = 'week' | 'month' | 'custom' | undefined;

export const useCalendarFilters = (range?: Range) => {
	const { messages } = useI18n();

	const [from, setFrom] = useState<Moment | undefined>();
	const [to, setTo] = useState<Moment | undefined>();
	const [rangeSelected, setRangeSelected] = useState<Range>(range);
	const [isChangedPresence, setIsChangedPresence] = useState<boolean>(false);
	const [type, setType] = useState<string>();

	const getInitialValues = () => {
		if (rangeSelected === 'week') {
			setFrom(moment().startOf('w'));
			setTo(moment().endOf('w'));
		} else {
			setFrom(moment().startOf('M'));
			setTo(moment().endOf('M'));
		}
	};

	const onChangeType = (type?: 'practice' | 'game' | 'other') => {
		setType(type);
	};

	const onRangeSelected = (range: 'week' | 'month' | 'custom') => {
		setRangeSelected(range);
	};

	const onChangeFrom = (from?: Moment) => {
		setFrom(from);
	};
	const onChangeTo = (to?: Moment) => {
		setTo(to);
	};

	const onChangePresence = () => {
		setIsChangedPresence(!isChangedPresence);
	};

	const eventsType = [
		{
			label: messages['practice'],
			value: 'practice',
		},
		{
			label: messages['match_game'],
			value: 'game',
		},
		{
			label: messages['other'],
			value: 'other',
		},
	];

	const onChangeDate = (range: 'week' | 'month', change: 'add' | 'subtract') => {
		if (range === 'week') {
			if (change === 'add') {
				setFrom(moment(from).add(1, 'w').startOf('w'));
				setTo(moment(to).add(1, 'w').endOf('w'));
			} else {
				setFrom(moment(from).subtract(1, 'w').startOf('w'));
				setTo(moment(to).subtract(1, 'w').endOf('w'));
			}
		} else {
			if (change === 'add') {
				setFrom(moment(from).add(1, 'M').startOf('M'));
				setTo(moment(to).add(1, 'M').endOf('M'));
			} else {
				setFrom(moment(from).subtract(1, 'M').startOf('M'));
				setTo(moment(to).subtract(1, 'M').endOf('M'));
			}
		}
	};

	useEffect(() => {
		getInitialValues();
	}, []);

	return {
		from,
		to,
		rangeSelected,
		type,
		onChangeDate,
		onChangeFrom,
		onChangeTo,
		onChangePresence,
		onChangeType,
		onRangeSelected,
		eventsType,
	};
};
