import { Button } from '@/components/button';
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerHeader,
	DrawerNested,
	DrawerTitle,
	DrawerTrigger,
} from '@/components/drawer';
import { RadioGroup, RadioGroupItem } from '@/components/radio-group';
import { useToast } from '@/components/toast';
import { useConan } from '@golee/gle-conan-tracker';
import { DownloadIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { sendInstantNotification } from '../../../../../api/hermes/notifications';
import { useOrgPerson } from '../../../../../context/org-person/use-org-person';
import { useModal } from '../../../../../hooks/use-modal';
import { MimeTypeIcon } from '../../mime-type-icon';
import { getPrintTemplates, PrintTemplate } from './api';

type Holder = {
	value: string;
	label: string;
};

export const RequestDocument = () => {
	const { toast } = useToast();
	const { orgPerson } = useOrgPerson();
	const { trackEvent } = useConan();

	const [printTemplates, setPrintTemplates] = useState<PrintTemplate[]>([]);
	const [selectedDocument, setSelectedDocument] = useState<PrintTemplate>();

	const requestModal = useModal();
	const needHolderModal = useModal();

	const retrievePrintTemplates = async () => {
		const resp = await getPrintTemplates();
		setPrintTemplates(resp.data);
	};

	useEffect(() => {
		retrievePrintTemplates();
	}, []);

	const requestDocument = async (document: PrintTemplate, holder?: Holder) => {
		requestModal.closeModal();
		needHolderModal.closeModal();

		toast({
			title: 'Documento richiesto',
			description: `Il documento ${document.label} è stato richiesto alla società`,
		});

		const label = holder ? `${document.label} a nome di ${holder.label}` : document.label;

		await sendInstantNotification(orgPerson.orgPersonId, orgPerson.organizationId, {
			occurrenceType: 'DocumentRequestedByOrgPerson',
			ownerType: 'OrgPersonDocuments',
			metadata: {
				documentName: label,
			},
			origin: 'user',
		});

		trackEvent('family.document-requested', { document_label: document.label });
	};

	const onRequestDocument = async () => {
		if (!selectedDocument) return;

		if (selectedDocument.askForHolder && orgPerson.parents?.length) {
			needHolderModal.openModal();
			return;
		}

		requestDocument(selectedDocument);
	};

	const onRequestDocumentWithHolder = async (holder: Holder) => {
		if (!selectedDocument) return;
		if (!holder) return;

		requestDocument(selectedDocument, holder);
	};

	const isDocumentSelected = (id?: string) => {
		if (!id) return false;
		return selectedDocument?._id === id;
	};

	return (
		<div>
			<Drawer
				open={requestModal.isModalVisible}
				onOpenChange={status => (!status ? requestModal.closeModal() : requestModal.openModal())}>
				<DrawerTrigger>
					<Button variant={'secondary'}>
						<DownloadIcon size={18} className="mr-2" /> Richiedi
					</Button>
				</DrawerTrigger>
				<DrawerContent>
					<DrawerHeader>
						<DrawerTitle>Richiedi documento alla società</DrawerTitle>
						<DrawerDescription>Seleziona quale documento vuoi richiedere</DrawerDescription>
					</DrawerHeader>

					<div className="p-4">
						<div className="flex flex-col gap-2">
							{printTemplates.map(pt => (
								<div
									key={pt._id}
									onClick={() => setSelectedDocument(pt)}
									className={`flex cursor-pointer items-center justify-start gap-3 rounded-md px-2 py-2 ${
										isDocumentSelected(pt._id)
											? 'border border-solid border-blue-100 bg-blue-50'
											: ''
									}`}>
									<div className={'mr-2 w-6'}>
										<MimeTypeIcon mimeType={'application/pdf'} />
									</div>
									<p className={`text-sm ${isDocumentSelected(pt._id) ? 'font-medium' : ''}`}>
										{pt.label}
									</p>
								</div>
							))}
							<div className={'mt-4 flex flex-col'}>
								<Button disabled={!selectedDocument} onClick={onRequestDocument}>
									Richiedi
								</Button>
							</div>
						</div>
					</div>

					<SelectHolderDrawer
						open={needHolderModal.isModalVisible}
						onCancel={needHolderModal.closeModal}
						onComplete={onRequestDocumentWithHolder}
					/>
				</DrawerContent>
			</Drawer>
		</div>
	);
};

const SelectHolderDrawer = (props: { open: boolean; onCancel: () => void; onComplete: (holder: Holder) => void }) => {
	const { orgPerson } = useOrgPerson();

	const holders: Holder[] = [
		{
			value: orgPerson.orgPersonId,
			label: `${orgPerson.surname} ${orgPerson.name}`,
		},
		...(orgPerson.parents?.map(parent => ({
			value: parent._id,
			label: `${parent.surname} ${parent.name}`,
		})) || []),
	];

	const [selectedHolder, setSelectedHolder] = useState<Holder>(holders[0]);

	return (
		<DrawerNested open={props.open} onOpenChange={status => !status && props.onCancel?.()}>
			<DrawerContent>
				<DrawerHeader>
					<DrawerTitle>Seleziona intestatario</DrawerTitle>
					<DrawerDescription>Decidi a chi vuoi intestare il documento richiesto</DrawerDescription>
				</DrawerHeader>

				<div className="p-4">
					<RadioGroup
						defaultValue={orgPerson.orgPersonId}
						onValueChange={value => setSelectedHolder(holders.find(h => h.value === value)!)}>
						{holders.map((holder, index) => (
							<div key={index} className="flex items-center space-x-2 py-3">
								<RadioGroupItem value={holder.value} id={`holder-${holder.value}`} />
								<label htmlFor={`holder-${holder.value}`} className="text-sm font-medium">
									{holder.label}
								</label>
							</div>
						))}
					</RadioGroup>
					<div className={'mt-4 flex flex-col'}>
						<Button disabled={!selectedHolder} onClick={() => props.onComplete(selectedHolder)}>
							Conferma
						</Button>
					</div>
				</div>
			</DrawerContent>
		</DrawerNested>
	);
};
