import { FormikContextType } from 'formik';
import { Metadata, TOrgPerson } from '../../api/people/metadata';

export type OrgPersonFormProps = {
	/**
	 * Org person in question.
	 */
	orgPerson?: TOrgPerson;

	/**
	 * Force readOnly state to all fields.
	 */
	readOnly?: boolean;
};

export type OrgPersonFormFieldProps = {
	formContext: FormikContextType<Metadata>;
	readOnly?: boolean;
};

export enum ContactType {
	primaryEmail = 'primaryEmail',
}
