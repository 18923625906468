import {
	BirthdateSection,
	GroupsSection,
	MedicalExaminationSection,
	PaymentsSection,
} from '../../../../../../components';
import { MembershipNumberSection } from '../../../../../../components/org-person-sections/membership-number';
import { SubmitButtons } from '../../../../../../components/submit-buttons/submit-buttons';
import { ViewProps } from '../../types';

export const GeneralView = (props: ViewProps & { isRenaming: boolean }) => {
	if (!props.orgPerson) return <></>;

	return (
		<>
			{props.canEdit && !props.isRenaming && (
				<SubmitButtons isEditing={props.isEditing} onChangeEdit={value => props.onChangeEdit(value)} />
			)}

			<BirthdateSection orgPerson={props.orgPerson} />
			<GroupsSection orgPerson={props.orgPerson} />
			<MedicalExaminationSection orgPerson={props.orgPerson} />
			<PaymentsSection />
			<MembershipNumberSection orgPerson={props.orgPerson} />
		</>
	);
};
