import { OrgPerson } from '../../context/org-person';
import { Section } from '../section';

export const GroupsSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const groups = orgPerson.groups?.filter(group => !group.groupType);

	if (!groups || !groups.length) return <></>;

	return (
		<Section title={'Squadre / Gruppi'}>
			<p>{groups.map(group => group.groupName).join(', ')}</p>
		</Section>
	);
};
