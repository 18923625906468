import { MedicalExaminationSection } from '../../../../../components';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { ViewProps } from '../types';

export const MedicalView = (props: ViewProps) => {
	if (!props.orgPerson) return <></>;

	return (
		<>
			<SubmitButtons isEditing={props.isEditing} onChangeEdit={value => props.onChangeEdit(value)} />
			<div className="my-2.5 flex flex-col gap-2.5">
				<MedicalExaminationSection showAllData orgPerson={props.orgPerson} />
			</div>
		</>
	);
};
