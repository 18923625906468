import { Button } from '@/components/button';
import { useToast } from '@/components/toast';
import { useState } from 'react';
import { AvailablePaymentMethod, getCheckoutLink } from '../../../api/payments/link';

const useCheckout = (organizationId: string, trancheId: string) => {
	const { toast } = useToast();
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<AvailablePaymentMethod>();
	const [isLoadingLink, setIsLoadingLink] = useState<boolean>(false);
	const [checkoutLink, setCheckoutLink] = useState<string>();

	const onPaymentMethodChange = async (paymentMethod: AvailablePaymentMethod) => {
		if (isLoadingLink) return;

		setIsLoadingLink(true);
		setSelectedPaymentMethod(paymentMethod);

		try {
			const { url } = await getCheckoutLink({
				organizationId: organizationId,
				trancheId: trancheId,
				paymentMethod,
			});

			setCheckoutLink(url);
		} catch (err) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsLoadingLink(false);
		}
	};

	return {
		selectedPaymentMethod,
		onPaymentMethodChange,
		checkoutLink,
		isLoadingLink,
	};
};

export const PaymentButton = (props: {
	organizationId: string;
	trancheId: string;
	availablePaymentMethods: AvailablePaymentMethod[];
}) => {
	const { onPaymentMethodChange, selectedPaymentMethod, checkoutLink, isLoadingLink } = useCheckout(
		props.organizationId,
		props.trancheId
	);

	if (!props.availablePaymentMethods.length) {
		return (
			<div>
				<p className="rounded bg-yellow-100 p-2 text-yellow-800">
					Questa società non ha nessun metodo di pagamento abilitato al momento, riprova più tardi.
				</p>
			</div>
		);
	}

	return (
		<div>
			<PaymentMethodSelector
				paymentMethods={props.availablePaymentMethods}
				selected={selectedPaymentMethod}
				onChange={onPaymentMethodChange}
			/>

			<div className="mb-5 flex justify-center">
				<a href={checkoutLink} className="block">
					<Button
						isLoading={isLoadingLink}
						disabled={!checkoutLink || isLoadingLink}
						size={'lg'}
						className="text-lg">
						{!checkoutLink
							? 'Seleziona metodo di pagamento'
							: isLoadingLink
								? 'Attendi'
								: 'Procedi al pagamento'}
					</Button>
				</a>
			</div>
		</div>
	);
};

export const PaymentMethodSelector = (props: {
	paymentMethods: AvailablePaymentMethod[];
	selected?: AvailablePaymentMethod;
	onChange: (paymentMethod: AvailablePaymentMethod) => void;
}) => {
	return (
		<div className="my-5">
			<h3 className="mb-4">Seleziona un metodo di pagamento</h3>

			<div className="flex items-center justify-between gap-4">
				{props.paymentMethods.map((paymentMethod, index) => (
					<PaymentMethodCard
						key={`pm-${index}`}
						paymentMethod={paymentMethod}
						isActive={props.selected === paymentMethod}
						onClick={() => props.onChange(paymentMethod)}
					/>
				))}
			</div>
		</div>
	);
};

const PaymentMethodCard = (props: {
	paymentMethod: AvailablePaymentMethod;
	isActive?: boolean;
	onClick: () => void;
}) => {
	switch (props.paymentMethod) {
		case 'card': {
			return (
				<div
					onClick={props.onClick}
					className={`flex h-24 w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-solid border-gray-200 p-2 hover:border-gray-400 hover:bg-gray-100 ${
						props.isActive ? 'border-gray-400 bg-gray-100' : ''
					}`}>
					<img
						alt="sepa"
						src={`${window._env_.PUBLIC_URL}images/visa-mastercard.png`}
						style={{ width: 70 }}
					/>
					<p className="text-center font-medium">Carta</p>
				</div>
			);
		}
		case 'sepa_debit': {
			return (
				<div
					onClick={props.onClick}
					className={`flex h-24 w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-solid border-gray-200 p-2 hover:border-blue-400 hover:bg-blue-100 ${
						props.isActive ? 'border-blue-400 bg-blue-100' : ''
					}`}>
					<p className="text-center font-extrabold text-blue-700">
						S<span className="text-yellow-400">€</span>PA
					</p>
					<p className="text-center font-medium">Bonifico</p>
				</div>
			);
		}
		case 'klarna': {
			return (
				<div
					onClick={props.onClick}
					className={`flex h-24 w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-solid border-[#ffd6e1] p-2 hover:border-[#FFBACD] hover:bg-[#FFBACD] ${
						props.isActive ? 'border-[#FFBACD] bg-[#FFBACD]' : ''
					}`}>
					<img alt="klarna" src={`${window._env_.PUBLIC_URL}images/klarna.png`} style={{ width: 80 }} />
					<p className="text-center font-medium">In 3 rate</p>
				</div>
			);
		}
		default: {
			return <></>;
		}
	}
};
