import { AddressSection, ContactsSection } from '../../../../../components';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { ViewProps } from '../types';

export const ContactsView = (props: ViewProps) => {
	return (
		<>
			<SubmitButtons isEditing={props.isEditing} onChangeEdit={value => props.onChangeEdit(value)} />
			<div className="my-2.5 flex flex-col gap-2.5">
				<ContactsSection orgPerson={props.orgPerson} />
				<AddressSection orgPerson={props.orgPerson} />
			</div>
		</>
	);
};
