import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { getEventsByParticipant } from '../api';
import { CalendarEvent } from '../types';

// TODO: if someone has more that 50 events per week, we need to change this
const LIMIT = 50;

export const useCalendarEvents = (orgPersonId: string, organizationId: string) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [events, setEvents] = useState<CalendarEvent[]>([]);

	const [week, setWeek] = useState<{ start: Moment; end: Moment }>({
		start: moment().startOf('week').startOf('day'),
		end: moment().endOf('week').endOf('day'),
	});

	const fetchEvents = async (start: Moment, end: Moment, options?: { prefetch?: boolean }) => {
		setIsLoading(true);

		try {
			const { data } = await getEventsByParticipant(orgPersonId, organizationId, {
				fromDate: start.startOf('d').toISOString(),
				toDate: end.endOf('d').toISOString(),
				limit: LIMIT,
			});

			if (!options?.prefetch) {
				setEvents(data);
			}
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	const fetchPreviousWeekEvents = (options?: { prefetch?: boolean }) => {
		const start = week.start.clone().subtract(1, 'week');
		const end = week.end.clone().subtract(1, 'week');

		setWeek({
			start,
			end,
		});

		void fetchEvents(start, end, options);
	};

	const fetchNextWeekEvents = (options?: { prefetch?: boolean }) => {
		const start = week.start.clone().add(1, 'week');
		const end = week.end.clone().add(1, 'week');

		setWeek({
			start,
			end,
		});

		void fetchEvents(start, end, options);
	};

	useEffect(() => {
		void fetchEvents(week.start, week.end);
	}, []);

	return {
		week,
		events,
		fetchPreviousWeekEvents,
		fetchNextWeekEvents,
		isLoading,
	};
};
