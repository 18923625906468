import { Loader } from '@gle/base-ui.loader';

// TODO: need to be renamed
export const LoadingLayout = () => {
	return (
		<div className="flex items-center justify-center pt-40">
			<Loader color="gray-lighter" />
		</div>
	);
};
