import { cn } from '@/lib/utils';

export type HeadingProps = {
	level?: 1 | 2 | 3;
} & React.HTMLAttributes<HTMLHeadingElement>;

const Heading = ({ level = 1, className, ...props }: HeadingProps) => {
	switch (level) {
		case 1:
			return (
				<h1 {...props} className={cn(`text-2xl font-bold tracking-tight`, className)}>
					{props.children}
				</h1>
			);
		case 2:
			return (
				<h2 {...props} className={cn(`text-xl font-bold tracking-tight`, className)}>
					{props.children}
				</h2>
			);
		case 3:
			return (
				<h3 {...props} className={cn(`text-lg font-bold tracking-tight`, className)}>
					{props.children}
				</h3>
			);
	}
};

export { Heading };
