import { Skeleton } from '@/components/skeleton';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { OrgPerson } from '../../context/org-person';
import { useCustomFields } from '../../hooks/custom-input-fields';
import { useMetadata } from '../../hooks/use-metadata';
import { CustomInputFields } from '../custom-fields/custom-input-fields';

export const MetadataCustomFieldsForm = ({ orgPerson, isEditing }: { orgPerson: OrgPerson; isEditing: boolean }) => {
	const { customFields } = useCustomFields();
	const { data, set, isLoading } = useMetadata({
		orgPersonId: orgPerson.orgPersonId,
		season: orgPerson.season,
	});
	const isSaving = useRef<boolean>(false);

	//TODO: refactor this
	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				<Skeleton className="h-5" />
				<Skeleton className="h-5" />
				<Skeleton className="h-5" />
				<Skeleton className="h-5" />
			</div>
		);
	}

	//TODO make dynamic validation schema based on custom fields
	const schema = Yup.object().shape({
		athlete: Yup.object().shape({
			technicalProfile: Yup.object().shape({
				'foot-n': Yup.number(),
			}),
		}),
	});

	return (
		<>
			<Formik initialValues={data} validationSchema={schema} enableReinitialize onSubmit={values => set(values)}>
				{formContext => {
					if (formContext.dirty && !isSaving.current) {
						isSaving.current = true;
						formContext.submitForm().then(() => {
							isSaving.current = false;
						});
					}
					return (
						<Form>
							<CustomInputFields fields={customFields} formContext={formContext} isEditing={isEditing} />
						</Form>
					);
				}}
			</Formik>
		</>
	);
};
