import { apiClient } from '../client';

type DocumentSignature = {
	signatureId: string;
	status: 'pending' | 'completed';
};

export type DocumentModel = {
	_id: string;
	url: string;
	name: string;
	refEntity: string;
	typeEntity: 'org_person' | 'organization';
	expirationDate?: string;
	mimeType?: string;
	__is_edit?: boolean;
	createdAt?: string;
	seenFromOrgPerson?: boolean;
	signature?: null | DocumentSignature;
};

const getDocuments = async (type_entity: string, ref_entity: string) => {
	return await apiClient('documents').get<DocumentModel[]>(
		`documents?type_entity=${type_entity}&ref_entity=${ref_entity}`
	);
};

const getDocument = async (documentId: string) => {
	return await apiClient('documents').get<DocumentModel>(`documents/${documentId}?type_entity=org_person`);
};

const getDocumentsCount = async (params: { ref_entity: string; seenFromOrgPerson: boolean }) =>
	apiClient('documents').get<number>(`documents/count`, {
		params: {
			type_entity: 'org_person',
			...params,
		},
	});

const addDocument = async (params: { mimeType: string; name: string; ref_entity: string; url: string }) => {
	return apiClient('documents').post<DocumentModel>(
		`documents`,
		{
			...params,
			type_entity: 'org_person',
			headers: {},
		},
		{}
	);
};

const signDocument = async (signatureId: string, signedDocumentAsBase64: string) => {
	return await apiClient('documents').post<void>(`signatures/${signatureId}/complete`, {
		signedDocument: signedDocumentAsBase64,
	});
};

export { addDocument, getDocument, getDocuments, getDocumentsCount, signDocument };
