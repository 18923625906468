import { useToast } from '@/components/toast';
import { isValidDate } from '@gle/base-ui.inputs.input-date';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Metadata } from '../../../../../api/people/metadata';
import { InputField, MedicalExaminationSection } from '../../../../../components';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { InputDateField } from '../../../../../core/inputs/DatePicker';
import { MetadataForm } from '../../../../../core/metadata';
import { sendMemberChangedDataNotification, translationsMap } from '../../utils';
import { metadataDatesValidationSchema } from '../general/utils';
import { FormProps } from '../types';

export const MedicalForm = (props: FormProps) => {
	const { messages } = useI18n();
	const { toast } = useToast();
	const { trackEvent } = useConan();

	const onSubmit = async (values: Metadata, set: any, formTouchedValues?: { dates?: any; medicalProfile?: any }) => {
		await set(
			values,
			() => {
				toast({
					variant: 'destructive',
					title: 'Errore',
					description: 'Si è verificato un errore imprevisto, riprova più tardi',
				});
			},
			() => {
				toast({
					title: 'Salvataggio completato',
					description: 'I dati sono stati aggiornati con successo',
				});
			}
		);

		const touchedValues = [];

		if (formTouchedValues?.dates) {
			for (const value in formTouchedValues.dates) {
				touchedValues.push(translationsMap.get(value));
			}
		}
		if (formTouchedValues?.medicalProfile) {
			for (const value in formTouchedValues.medicalProfile) {
				touchedValues.push(translationsMap.get(value));
			}
		}
		props.onSave();
		props.onChangeEdit(false);
		if (touchedValues.length) {
			sendMemberChangedDataNotification(props.orgPerson, touchedValues as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: touchedValues.join(', '),
				section: 'medical',
			});
		}
	};

	const validationSchema = yup.object().shape({
		person: yup.object().shape({
			dates: metadataDatesValidationSchema(messages),
		}),
	});
	return (
		<>
			<div>
				<MetadataForm orgPersonId={props.orgPerson?.orgPersonId} season={props.orgPerson.season}>
					{({ data, set, isSaving }) => {
						return (
							<Formik
								validationSchema={validationSchema}
								enableReinitialize
								initialValues={data}
								onSubmit={() => {}}>
								{formContext => {
									return (
										<Form>
											<SubmitButtons
												formContext={formContext}
												isSaving={isSaving}
												isEditing={props.isEditing}
												onChangeEdit={value => props.onChangeEdit(value)}
												onSubmit={() =>
													onSubmit(
														formContext.values,
														set,
														formContext.dirty
															? {
																	//@ts-ignore
																	dates: formContext.touched?.person?.dates,

																	medicalProfile:
																		//@ts-ignore
																		formContext.touched?.person?.medicalProfile,
																}
															: undefined
													)
												}
											/>
											<div className="my-2.5 flex flex-col gap-2.5">
												<MedicalExaminationSection orgPerson={props.orgPerson} />
												<InputDateField
													name="person.dates.medicalExaminationScheduledDate"
													label={messages['scheduled_date']}
													isClearable
													onChange={(value, formats) => {
														formContext.setFieldValue(
															'person.dates.medicalExaminationScheduledDate',
															value && isValidDate(value) ? formats.ISOString : value
														);
													}}
												/>
												<InputField
													label={messages['health_card']}
													name="person.medicalProfile.healthCardNumber"
													autoComplete="off"
												/>
												<InputField
													name="person.medicalProfile.allergies"
													type="textarea"
													label={messages['allergies']}
													autoComplete="off"
												/>
											</div>
										</Form>
									);
								}}
							</Formik>
						);
					}}
				</MetadataForm>
			</div>
		</>
	);
};
