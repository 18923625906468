import { useI18n } from '@gle/providers.i18n';
import { CustomField } from '../../hooks/custom-input-fields/types';
import { useCustomSelect } from '../../hooks/use-custom-select';

export const CustomValueField = (props: { field: CustomField; value: any; label?: string }) => {
	const { messages } = useI18n();

	switch (props.field.type) {
		case 'BOOLEAN':
			return <p>{props.value ? messages['yes'] : messages['no']}</p>;

		case 'NUMBER':
		case 'TEXT':
		case 'DATE':
			return <p>{props.value}</p>;

		case 'SELECT':
			return <p>{messages[`${props.field.labelKey}.${props.value}`]}</p>;
		case 'CUSTOM-SELECT':
			return <CustomOptionValue optionsKey={props.field.optionsKey} value={props.value} />;

		default:
			return <></>;
	}
};

export const CustomOptionValue = (props: { optionsKey: string; value: string }) => {
	const { options } = useCustomSelect(props.optionsKey);

	const getValue = () => {
		const item = options.find(option => option.value === props.value);
		return item?.label;
	};

	return (
		<>
			<p>{getValue()}</p>
		</>
	);
};
