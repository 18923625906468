export type PrintTemplate = {
	_id: string;
	unique_code: string;
	label: string;
	askForHolder?: boolean;
};

const PRINT_TEMPLATES: PrintTemplate[] = [
	{
		_id: '1',
		unique_code: '',
		label: 'Dichiarazione di pagamento quota 730',
		askForHolder: true,
	},
	{
		_id: '2',
		unique_code: '',
		label: 'Visita medica agonistica',
	},
	{
		_id: '3',
		unique_code: '',
		label: 'Visita medica non agonistica',
	},
	{
		_id: '4',
		unique_code: '',
		label: 'Certificazione per crediti scolastici',
	},
	{
		_id: '5',
		unique_code: '',
		label: 'Attestazione pagamento e frequenza',
	},
	{
		_id: '6',
		unique_code: '',
		label: 'Modulo per uscita anticipata',
	},
	{
		_id: '7',
		unique_code: '',
		label: 'Bando Dote Sport',
	},
	{
		_id: '8',
		unique_code: '',
		label: 'Nulla Osta Sportivo',
	},
];

const getPrintTemplates = (): Promise<{
	data: PrintTemplate[];
}> => {
	return new Promise((resolve, reject) => {
		resolve({ data: PRINT_TEMPLATES });
	});
};

export { getPrintTemplates };
