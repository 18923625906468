import { InputDate } from '@gle/base-ui.inputs.input-date';
import { Select } from '@gle/base-ui.inputs.select';
import { Text } from '@gle/base-ui.typography.text';
import { useI18n } from '@gle/providers.i18n';
import { Moment } from 'moment';

type EventsStatisticsFiltersProps = {
	eventsType?: { label: string; value: string }[];
	from?: Moment;
	to?: Moment;
	rangeSelected?: 'week' | 'month' | 'custom';
	onChangeType?: (type?: 'practice' | 'game' | 'other') => void;
	onChangeFrom?: (from?: Moment) => void;
	onChangeTo?: (to?: Moment) => void;
	onRangeSelected?: (range: 'week' | 'month' | 'custom') => void;
};

export const EventsStatisticsFilters = (props: EventsStatisticsFiltersProps) => {
	return (
		<>
			<div
				style={{
					display: 'block',
					marginBottom: 15,
				}}>
				<CustomDateFilter
					from={props.from}
					to={props.to}
					onChangeFrom={props.onChangeFrom}
					onChangeTo={props.onChangeTo}
					onRangeSelected={props.onRangeSelected}
				/>
				{props.eventsType && (
					<EventsTypeSelect eventsType={props.eventsType} onChangeType={props.onChangeType} />
				)}
			</div>
		</>
	);
};

type CustomDateFilterProps = {
	from?: Moment;
	to?: Moment;
	onChangeFrom?: (from?: Moment) => void;
	onChangeTo?: (to?: Moment) => void;
	onRangeSelected?: (range: 'week' | 'month' | 'custom') => void;
};
const CustomDateFilter = (props: CustomDateFilterProps) => {
	return (
		<div style={{ marginTop: 15 }}>
			<Text style={{ marginBottom: 10 }} color="gray" size="s">
				Seleziona periodo personalizzato
			</Text>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: 10,
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '50%',
					}}>
					<Text size="s" color="gray-light">
						da
					</Text>
					<InputDate
						value={props.from}
						disableType
						onChange={(v, formats) => {
							// @ts-ignore
							props.onChangeFrom?.(formats.moment);
							props.onRangeSelected?.('custom');
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 0,
						width: '50%',
					}}>
					<Text size="s" color="gray-light">
						a
					</Text>
					<InputDate
						value={props.to}
						disableType
						minDate={props.from?.toDate()}
						onChange={(v, formats) => {
							// @ts-ignore
							props.onChangeTo?.(formats.moment);
							props.onRangeSelected?.('custom');
						}}
					/>
				</div>
			</div>
		</div>
	);
};

type EventsTypeSelectProps = {
	eventsType?: { label: string; value: string }[];
	onChangeType?: (type?: 'practice' | 'game' | 'other') => void;
};
const EventsTypeSelect = (props: EventsTypeSelectProps) => {
	const { messages } = useI18n();

	return (
		<div>
			<Text style={{ marginBottom: 0, marginTop: 15 }} color="gray" size="s">
				{messages['event_type']}
			</Text>
			<Select
				placeholder={messages['select']}
				options={props.eventsType}
				onChange={(type: any) => {
					if (type) {
						return props.onChangeType?.(type.value);
					}
					return props.onChangeType?.(undefined);
				}}
				isClearable
			/>
		</div>
	);
};
