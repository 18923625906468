import { useState } from 'react';
import * as yup from 'yup';
import { addContactToOrgPerson, changeContactToOrgPerson, removeContactFromOrgPerson } from '../api/people/contacts';
import { OrgPerson } from '../context/org-person/types';
import { ContactType } from '../pages/private/types';

export const useOrgPersonEmail = (orgPerson?: OrgPerson) => {
	const updateEmail = useOrgPersonEmailUpdate();

	const [oldEmail, setOldEmail] = useState<string | undefined>(orgPerson?.contacts?.primaryEmail);
	const [email, setEmail] = useState<string | undefined>(oldEmail);

	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [editEnabled, setEditEnabled] = useState<boolean>(false);

	const onChange = (value?: string) => {
		setIsDirty(value !== orgPerson?.contacts?.primaryEmail);
		setEmail(value);
	};

	const saveEmail = async () => {
		if (!orgPerson) return;
		await updateEmail(orgPerson.orgPersonId, orgPerson.season, oldEmail, email);
		setOldEmail(email);

		setIsDirty(false);
	};

	const reset = (value?: string) => {
		setEditEnabled(false);
		setEmail(value);
		setIsDirty(false);
	};

	const isValid = () => {
		try {
			const schema = yup.object().shape({ email: yup.string().nullable().email('invalid') });
			schema.validateSync({ email });
			return true;
		} catch (err) {
			return false;
		}
	};

	return {
		saveEmail,
		onChange,
		email,
		isDirty,
		reset,
		isValid,
		editEnabled,
		setEditEnabled,
	};
};

export const useOrgPersonEmailUpdate = () => {
	const addContact = async (orgPersonId: string, contact: string, contactType: ContactType, season: string) => {
		await addContactToOrgPerson({
			orgPersonId,
			contact,
			contactType,
			season,
		});
	};

	const changeContact = async (orgPersonId: string, contact: string, contactType: ContactType, season: string) => {
		await changeContactToOrgPerson({
			orgPersonId,
			contact,
			contactType,
			season,
		});
	};

	const removeContact = async (orgPersonId: string, contactType: ContactType, season: string) => {
		await removeContactFromOrgPerson({
			orgPersonId,
			contactType,
			season,
		});
	};

	const update = async (orgPersonId: string, season: string, oldEmail?: string, newEmail?: string) => {
		const cmd = getContactUpdateCommand(oldEmail, newEmail);

		try {
			if (cmd === 'add') {
				await addContact(orgPersonId, newEmail!, ContactType.primaryEmail, season);
			} else if (cmd === 'change') {
				await changeContact(orgPersonId, newEmail!, ContactType.primaryEmail, season);
			} else if (cmd === 'remove') {
				await removeContact(orgPersonId, ContactType.primaryEmail, season);
			}
		} catch (err: any) {
			throw err;
		}
	};

	return update;
};

export const getContactUpdateCommand = (
	oldEmail?: string,
	newEmail?: string
): 'add' | 'remove' | 'change' | undefined => {
	if (!oldEmail && newEmail) return 'add';

	if (!newEmail && oldEmail) return 'remove';

	if (oldEmail === newEmail) return undefined;

	return 'change';
};
