import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Skeleton } from '@/components/skeleton';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import moment from 'moment';
import { useCalendarEvents } from '../hooks';
import { EventCard } from './event-card';

type EventListProps = { orgPersonId: string; organizationId: string };

export const EventList = (props: EventListProps) => {
	const { events, week, fetchNextWeekEvents, fetchPreviousWeekEvents, isLoading } = useCalendarEvents(
		props.orgPersonId,
		props.organizationId
	);

	return (
		<div className={'hide-scrollbar pb-5'}>
			<EventListHeader week={week} onPrevWeek={fetchPreviousWeekEvents} onNextWeek={fetchNextWeekEvents} />

			<div className="flex flex-col gap-5">
				{isLoading ? (
					<EventListSkeleton />
				) : (
					<>
						{!events.length ? (
							<p className="mt-5 text-center text-gray-500">
								Nessun evento in programma per la settimana selezionata
							</p>
						) : (
							<>
								{events.map(event => (
									<EventCard event={event} key={event._id} orgPersonId={props.orgPersonId} />
								))}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const EventListHeader = (props: {
	week: { start: moment.Moment; end: moment.Moment };
	onPrevWeek: () => void;
	onNextWeek: () => void;
}) => {
	return (
		<div className="mb-5 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
			<Heading>Calendario</Heading>
			<div className="flex items-center justify-between gap-5 rounded-md border border-solid border-slate-100">
				<Button onClick={props.onPrevWeek} variant={'ghost'} size={'icon'}>
					<ChevronLeftIcon size={18} />
				</Button>
				<p className="text-sm font-medium capitalize">
					{moment(props.week.start).format('ddd DD MMM')} - {moment(props.week.end).format('ddd DD MMM')}
				</p>
				<Button onClick={props.onNextWeek} variant={'ghost'} size={'icon'}>
					<ChevronRightIcon size={18} />
				</Button>
			</div>
		</div>
	);
};

const EventListSkeleton = () => {
	return (
		<div className="flex flex-col gap-4">
			<Skeleton className="h-24" />
			<Skeleton className="h-24" />
			<Skeleton className="h-24" />
		</div>
	);
};
