import { Skeleton } from '@/components/skeleton';
import { Metadata, MetadataParams } from '../../api/people/metadata';
import { useMetadata } from '../../hooks/use-metadata';

export type MetadataProps = MetadataParams;

export type MetadataFormProps = MetadataProps & {
	children: (props: MetadataInnerFormProps) => React.ReactNode;
};

export type MetadataInnerFormProps = {
	data: Metadata;
	set: (payload: Metadata, onError?: (err?: any) => void, onComplete?: () => void) => Promise<void>;
	isSaving?: boolean;
};

export const MetadataForm = (props: Omit<MetadataFormProps, 'role'>) => {
	const metadata = useMetadata({ season: props.season, orgPersonId: props.orgPersonId });
	const { isLoading, ...rest } = metadata;

	if (isLoading) {
		return (
			<div className="mt-5">
				{Array.apply(null, Array(5)).map((item, index) => (
					<Skeleton className="mb-2 h-5" />
				))}
			</div>
		);
	}

	if ((!rest.data || !Object.keys(rest.data).length) && props.orgPersonId) {
		return <></>;
	}

	return <>{props.children({ ...rest })}</>;
};
