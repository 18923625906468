import { apiClient } from '../client';

export type TOrgPerson = {
	id: string;
	orgPersonId: string;
	organizationId: string;
	name: string;
	surname: string;
	season: string;
	fiscalCode: string;
	profileId: null;
	roles: string[];
	contacts?: {
		primaryEmail?: string;
	};
	personMetadata: {
		avatar?: string;
		birthdate?: string;
		birthplace?: string;
		sex?: string;
		nationality?: string;
		address?: {
			address?: string;
			num?: string;
			cap?: string;
			nation?: string;
			region?: string;
			province?: string;
			town?: string;
		};
		dates?: {
			medicalExaminationExpirationDate?: string;
			birthdate?: string;
			medicalExaminationScheduledDate?: string;
		};
		contacts?: {
			primaryEmail?: string;
			primaryPhone?: string;
			secondaryEmail?: string;
			secondaryPhone?: string;
		};
		[key: string]: any;
	};
	groups?: {
		role?: 'athlete';
		groupId: string;
		groupName: string;
		groupType?: null | string;
	}[];
	metadata: {
		athlete: {
			refParents: string[];
			medicalProfile?: {
				medicalExaminationExpirationDate?: string;
				medicalExaminationScheduledDate?: string;
				medicalExaminationChargedTo?: string;
				medicalExaminationPlace?: string;
				isNotificationEnabled?: boolean;
				medicalExaminationType?: string;
				healthCardNumber?: string;
				allergies?: string;
				notes?: string;
				covidGreenPass?: boolean;
				covidGreenPassExpirationDate?: string;
			};
			technicalProfile?: {
				registrationNumber?: string;
				membershipFirstDate?: string;
				membershipExpirationDate?: string;
				membershipStatus?: string;
				role?: string;
				favoriteRole?: string;
				favoriteFoot?: string;
				height?: string;
				weight?: string;
				status?: string;
				hasCounselor?: boolean;
				counselorName?: string;
				shirtNumber?: string;
			};
			loanedStuff?: any[];
			privacy?: { [key: string]: { value: boolean; updatedAt: number } };
		};
	};
};

export type MetadataParams = {
	orgPersonId: string;
	season: string;
};

export type Metadata = {
	id?: string;
	orgPersonId?: string;
	person?: TOrgPerson['personMetadata'];
	athlete?: TOrgPerson['metadata']['athlete'];
};

const getMetadata = async (params: MetadataParams) => {
	return await apiClient('people').get<Metadata>(`org-people/${params.orgPersonId}/metadata`, {
		headers: {
			'x-gle-current-season': params.season,
			'x-gle-selected-season': params.season,
		},
	});
};

const setMetadata = async (params: MetadataParams, payload: Metadata) => {
	return await apiClient('people').patch<Metadata>(`org-people/${params.orgPersonId}/metadata`, payload, {
		headers: {
			'x-gle-current-season': params.season,
			'x-gle-selected-season': params.season,
		},
	});
};

export { getMetadata, setMetadata };
