import { Badge, BadgeVariant } from '@/components/badge';
import { DateTime } from 'luxon';
import { OrgPerson } from '../../context/org-person';
import { formatDate } from '../../utils/format-date';
import { Section } from '../section';

export const MedicalExaminationSection = ({
	orgPerson,
	showAllData,
}: {
	orgPerson: OrgPerson;
	showAllData?: boolean;
}) => {
	const medicalExaminationExaminationDate = orgPerson.personMetadata.dates?.medicalExaminationExpirationDate;

	return (
		<>
			<Section title={'Data scadenza visita medica'}>
				{medicalExaminationExaminationDate ? (
					<div className={'flex items-center justify-between'}>
						<p>{formatDate(medicalExaminationExaminationDate)}</p>
						<MedicalExaminationChip
							medicalExaminationExpirationDate={
								orgPerson.personMetadata.dates?.medicalExaminationExpirationDate
							}
						/>
					</div>
				) : (
					<p>{'Non impostata'}</p>
				)}
			</Section>

			{showAllData && <MedicalDataSection orgPerson={orgPerson} />}
		</>
	);
};

export const MedicalExaminationChip = (props: { medicalExaminationExpirationDate?: string }) => {
	const getOpts = (date: string | undefined): { variant: BadgeVariant; dateText: string } => {
		if (!date) {
			return { variant: 'default', dateText: 'Non impostata' };
		}
		const daysDiff = DateTime.fromISO(date).diff(DateTime.now(), ['days']).days;

		const dateText = daysDiff <= 0 ? 'Scaduta' : daysDiff > 30 ? 'Valida' : 'In Scadenza';
		const variant = dateText === 'Valida' ? 'success' : dateText === 'Scaduta' ? 'destructive' : 'warning';

		return { variant, dateText };
	};

	const { variant, dateText } = getOpts(props.medicalExaminationExpirationDate);

	return <Badge variant={variant}>{dateText}</Badge>;
};

const MedicalDataSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const medicalExaminationScheduledDate = orgPerson.personMetadata.dates?.medicalExaminationScheduledDate;

	return (
		<>
			<Section title="Data prevista visita medica">
				{medicalExaminationScheduledDate ? formatDate(medicalExaminationScheduledDate) : '-'}
			</Section>
			<Section title="Tessera sanitaria">
				{orgPerson.personMetadata.medicalProfile?.healthCardNumber || '-'}
			</Section>
			<Section title="Allergie">{orgPerson.personMetadata.medicalProfile?.allergies || '-'}</Section>
		</>
	);
};
