import { Heading } from '@/components/heading';
import { useToast } from '@/components/toast';
import { useConan } from '@golee/gle-conan-tracker';
import { LinkIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PdfEditor } from '../../../../@pdf/pdf-editor';
import { apiClient } from '../../../../api/client';
import { DocumentModel, signDocument } from '../../../../api/documents/documents';
import { NavigateBack } from '../../../../components';
import { RedirectBack } from '../../../../router/redirect-back';

export const DocumentDetail = () => {
	const { state } = useLocation();

	if (!state || !(state as any).document) {
		return <RedirectBack />;
	}

	const document = (state as any).document as DocumentModel;

	return <Inner document={document} />;
};

const Inner = (props: { document: DocumentModel }) => {
	const navigate = useNavigate();

	const { toast } = useToast();
	const { trackEvent } = useConan();

	const [isSigning, setIsSigning] = useState<boolean>(false);
	const [isSavingSignedDocument, setIsSavingSignedDocument] = useState<boolean>(false);

	const allowSignature = props.document.signature?.status === 'pending';

	const markDocumentAsSeen = async () => {
		if (props.document.typeEntity !== 'org_person') return;
		if (props.document.seenFromOrgPerson) return;

		try {
			await apiClient('documents').post(`documents/${props.document._id}/mark-as-seen`);
		} catch (err) {}
	};

	const onSignatureCompleted = async (base64: string) => {
		if (!allowSignature) return;
		if (!props.document.signature) return; // Just to avoid ? in stupid typescript

		setIsSavingSignedDocument(true);

		try {
			await signDocument(props.document.signature.signatureId, base64);
			void trackEvent('family.document-signed', { document_name: props.document.name });
			toast({ title: 'Firma completata', description: 'Documento firmato correttamente' });
		} catch (err) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore durante la firma del documento, riprova più tardi',
			});

			void trackEvent('family.document-signed-error', { error: JSON.stringify(err) });
		} finally {
			navigate(-1);
			setIsSavingSignedDocument(false);
		}
	};

	const initLayout = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		initLayout();
		markDocumentAsSeen();
	}, []);

	return (
		<div className="pb-5">
			<NavigateBack />
			<div className="mb-5 mt-2">
				<Heading level={2}>{props.document.name}</Heading>
				{!isSigning && (
					<a
						href={props.document.url}
						target="_blank"
						className="flex cursor-pointer items-center gap-1 text-sm font-medium text-sky-700 hover:underline"
						onClick={() => trackEvent('family.document-opened', { document_name: props.document.name })}>
						Apri documento nel browser <LinkIcon size={16} />
					</a>
				)}
			</div>

			{props.document.mimeType === 'application/pdf' ? (
				<PdfEditor
					url={props.document.url}
					allowSignature={allowSignature}
					isSigning={isSigning}
					isSavingSignedDocument={isSavingSignedDocument}
					onSignStarted={() => setIsSigning(true)}
					onSignCanceled={() => setIsSigning(false)}
					onSignCompleted={onSignatureCompleted}
				/>
			) : (
				<>
					{props.document.mimeType?.includes('image') ? (
						<div className="rounded bg-slate-100 p-3">
							<img src={props.document.url} className="mx-auto w-full max-w-lg" />
						</div>
					) : (
						<>
							<div className="flex flex-col items-center justify-center gap-1 rounded bg-slate-100 p-3">
								<p className="text-sm text-slate-600">Anteprima non disponibile</p>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};
