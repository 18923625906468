import { Button } from '@/components/button';
import { useToast } from '@/components/toast';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { setMetadata } from '../../../../../../api/people/metadata';
import { renameOrgPerson } from '../../../../../../api/people/profiles';
import { InputField } from '../../../../../../components';
import { ImageUploader } from '../../../../../../components/image-uploader/image-uploader';
import { OrgPerson } from '../../../../../../context/org-person/types';
import { useBoolean } from '../../../../../../hooks/use-boolean';
import { sendMemberChangedDataNotification, translationsMap } from '../../../utils';

type RenameOrgPersonFormProps = {
	onSave: (orgPerson: { name: string; surname: string }) => void;
	orgPerson: OrgPerson;
	onChangeRenaming: (value: boolean) => void;
};

export const RenameOrgPersonForm = (props: RenameOrgPersonFormProps) => {
	const { messages } = useI18n();
	const { toast } = useToast();
	const { trackEvent } = useConan();

	const [isSaving, setIsSaving] = useBoolean(false);

	const rename = async (
		orgPerson: { name: string; surname: string; avatar?: string },
		formTouchedValues?: { name?: any; surname?: any; avatar?: any }
	) => {
		const touchedValues = [];

		if (formTouchedValues) {
			for (const value in formTouchedValues) {
				touchedValues.push(translationsMap.get(value));
			}
		}

		setIsSaving(true);

		if (formTouchedValues?.avatar) {
			try {
				await setMetadata(
					{ orgPersonId: props.orgPerson.orgPersonId, season: props.orgPerson.season },
					{ person: { avatar: orgPerson.avatar } }
				);
			} catch {
				toast({
					variant: 'destructive',
					title: 'Errore',
					description: 'Si è verificato un errore imprevisto, riprova più tardi',
				});
			}
		}
		if (formTouchedValues?.name || formTouchedValues?.surname) {
			try {
				await renameOrgPerson({
					name: orgPerson.name,
					surname: orgPerson.surname,
					orgPersonId: props.orgPerson.orgPersonId,
					season: props.orgPerson.season,
				});

				props.onSave({ name: orgPerson.name, surname: orgPerson.surname });
			} catch (err) {
				toast({
					variant: 'destructive',
					title: 'Errore',
					description: 'Si è verificato un errore imprevisto, riprova più tardi',
				});
			} finally {
				setIsSaving(false);
			}
		}

		toast({
			title: 'Salvataggio completato',
			description: 'I dati sono stati aggiornati con successo',
		});

		if (touchedValues.length) {
			sendMemberChangedDataNotification(props.orgPerson, touchedValues as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: touchedValues.join(', '),
				section: 'name_surname',
			});
		}
		props.onChangeRenaming(false);

		setIsSaving(false);
	};

	const validationSchema = yup.object().shape({
		name: yup.string().required('Campo obbligatorio'),
		surname: yup.string().required('Campo obbligatorio'),
		avatar: yup.string().nullable(),
	});

	return (
		<Formik
			validationSchema={validationSchema}
			enableReinitialize
			initialValues={{
				name: props.orgPerson.name,
				surname: props.orgPerson.surname,
				avatar: props.orgPerson.personMetadata.avatar,
			}}
			onSubmit={() => {}}>
			{formContext => {
				return (
					<Form>
						<div className="-mt-5 flex justify-end gap-1.5">
							<Button
								variant={'secondary'}
								disabled={isSaving}
								onClick={() => {
									props.onChangeRenaming?.(false);
									formContext?.resetForm();
								}}>
								{messages['cancel']}
							</Button>
							<Button
								type="submit"
								isLoading={isSaving}
								onClick={() => {
									rename(
										{
											name: formContext.values.name,
											surname: formContext.values.surname,
											avatar: formContext.values.avatar,
										},
										formContext.dirty ? formContext.touched : undefined
									);
								}}>
								{messages['save']}
							</Button>
						</div>
						<ImageUploader
							url={props.orgPerson.personMetadata.avatar}
							style={{ marginBlock: 10 }}
							onChange={(url?: string) => {
								formContext.setFieldValue('avatar', url);
								formContext.setFieldTouched('avatar', true);
							}}
						/>
						<InputField name="name" label={messages['name']} pascalCase />
						<InputField name="surname" label={messages['surname']} pascalCase />
					</Form>
				);
			}}
		</Formik>
	);
};
