import { ReactNode } from 'react';

export const Content = (props: { children: ReactNode }) => {
	return (
		<div
			id="main-content"
			className={'mx-auto h-full w-full max-w-2xl flex-col bg-white px-3 pb-5 md:px-3'}
			style={{ paddingTop: '5rem' }}>
			{props.children}
		</div>
	);
};
