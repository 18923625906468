import { apiClient } from '../../../api/client';
import { Organization, OrgPerson, Parent } from '../types';

export const getOrganization = (organizationId: string) =>
	apiClient('club').get<Organization>(`club/${organizationId}`);

export const loadOrgPerson = (currentSeason: string) =>
	apiClient('people').get<OrgPerson>(`org-people/me`, {
		headers: {
			'x-gle-current-season': currentSeason,
			'x-gle-selected-season': currentSeason,
		},
	});

export const loadOrgPersonParents = (orgPersonId: string, currentSeason: string) =>
	apiClient('people').get<Parent[]>(`parent`, {
		params: {
			athlete: orgPersonId,
		},
		headers: {
			'x-gle-selected-season': currentSeason,
			'x-gle-current-season': currentSeason,
		},
	});
