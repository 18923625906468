import { useToast } from '@/components/toast';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Metadata } from '../../../../../api/people/metadata';
import { InputField, InputPhoneField } from '../../../../../components';
import { CountrySelectField } from '../../../../../components/selector/country-select-field';
import { TownsSelectField } from '../../../../../components/selector/italian-towns-select-field';
import { ProvincesSelectField } from '../../../../../components/selector/provinces-select-field';
import { RegionsSelectField } from '../../../../../components/selector/regions-select-field';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { InputEmail } from '../../../../../core/inputs/Input/email-input';
import { MetadataForm } from '../../../../../core/metadata';
import { useOrgPersonEmail } from '../../../../../hooks/use-org-person-email';
import { sendMemberChangedDataNotification, translationsMap } from '../../utils';
import { FormProps } from '../types';
import { contactsFormValidationSchema } from './utils';

export const ContactsForm = (props: FormProps) => {
	const { toast } = useToast();
	const { messages } = useI18n();

	const { trackEvent } = useConan();

	const { saveEmail, email, onChange: onEmailChange, isValid: isValidEmail } = useOrgPersonEmail(props.orgPerson);

	const onSubmit = async (values: Metadata, set: any, formTouchedValues?: { contacts?: {}; address?: {} }) => {
		if (!isValidEmail()) return;

		const touchedValues = [];

		if (formTouchedValues?.address) {
			for (const value in formTouchedValues.address) {
				touchedValues.push(translationsMap.get(value));
			}
		}

		if (formTouchedValues?.contacts) {
			for (const value in formTouchedValues.contacts) {
				touchedValues.push(translationsMap.get(value));
			}
		}

		try {
			if (values.person?.contacts?.primaryEmail || values.person?.contacts?.primaryEmail === '') {
				values.person.contacts.primaryEmail = undefined;
			}
			await saveEmail();

			await set(
				values,
				() => {
					toast({
						variant: 'destructive',
						title: 'Errore',
						description: 'Si è verificato un errore imprevisto, riprova più tardi',
					});
				},
				() => {
					toast({
						title: 'Salvataggio completato',
						description: 'I dati sono stati aggiornati con successo',
					});
				}
			);
			props.onSave();
			props.onChangeEdit(false);
			if (touchedValues.length) {
				sendMemberChangedDataNotification(props.orgPerson, touchedValues as string[]);
				void trackEvent('family.orgPerson-changed-data-from-app', {
					values: touchedValues.join(', '),
					section: 'contacts',
				});
			}
		} catch {}
	};
	const validationSchema = yup.object().shape({
		person: yup.object().shape({
			...contactsFormValidationSchema(messages),
		}),
	});
	return (
		<>
			<div>
				<MetadataForm orgPersonId={props.orgPerson?.orgPersonId} season={props.orgPerson.season}>
					{({ data, set, isSaving }) => {
						return (
							<Formik
								validationSchema={validationSchema}
								enableReinitialize
								initialValues={{
									...data,
									person: {
										...data.person,
										contacts: { ...data.person?.contacts, primaryEmail: email },
									},
								}}
								onSubmit={() => {}}>
								{formContext => {
									return (
										<Form>
											<SubmitButtons
												formContext={formContext}
												isSaving={isSaving}
												isEditing={props.isEditing}
												onChangeEdit={value => props.onChangeEdit(value)}
												onSubmit={() =>
													onSubmit(
														formContext.values,
														set,
														formContext.dirty
															? {
																	//@ts-ignore
																	contacts: formContext.touched?.person?.contacts,
																	//@ts-ignore
																	address: formContext.touched?.person?.address,
																}
															: undefined
													)
												}
											/>
											<div className="my-2.5 flex flex-col gap-2.5">
												<InputEmail
													name="person.contacts.primaryEmail"
													value={email}
													isValid={isValidEmail}
													onChange={(email?: string) => {
														onEmailChange(email);
														formContext.setFieldValue(
															'person.contacts.primaryEmail',
															email
														);
														if (!isValidEmail()) {
															formContext.setFieldError(
																'person.contacts.primaryEmail',
																messages['email_not_valid']
															);
														} else {
															formContext.setFieldError(
																'person.contacts.primaryEmail',
																undefined
															);
														}
													}}
												/>
												<InputPhoneField
													name="person.contacts.primaryPhone"
													label={messages['phone']}
												/>
												<CountrySelectField
													name="person.address.nation"
													label={messages['nation']}
													onChange={(value: any) => {
														formContext.setFieldTouched('person.address.nation', true);
														formContext.setFieldValue('address.region', undefined, false);
														formContext.setFieldValue('address.province', undefined, false);
													}}
												/>

												<RegionsSelectField
													name={'person.address.region'}
													label={messages['region']}
													onChange={() => {
														formContext.setFieldTouched('person.address.region', true);
													}}
												/>
												<ProvincesSelectField
													name={'person.address.province'}
													label={messages['province']}
													onChange={() => {
														formContext.setFieldTouched('person.address.province', true);
													}}
												/>

												<TownsSelectField
													name={'person.address.town'}
													label={messages['town']}
													value={formContext.values.person.address?.town}
													onChange={(option: any) => {
														formContext.setFieldTouched('person.address.town', true);
														formContext.setFieldValue(
															'person.address.town',
															option ? option.value : null
														);
													}}
												/>

												<InputField
													name={'person.address.address'}
													label={messages['address']}
												/>
												<InputField
													name={'person.address.num'}
													label={messages['civic_number']}
												/>
												<InputField name={'person.address.cap'} label={messages['cap']} />
											</div>
										</Form>
									);
								}}
							</Formik>
						);
					}}
				</MetadataForm>
			</div>
		</>
	);
};
