import { useState } from 'react';
import { getStatisticsByParticipant } from '../api';
import { ParticipantStatistics } from '../types';

export const useCalendarStats = (orgPersonId: string) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<ParticipantStatistics>();

	const load = async (params: { fromDate?: string; toDate?: string; date?: string; type?: string } = {}) => {
		setIsLoading(true);

		try {
			const { data: stats } = await getStatisticsByParticipant(orgPersonId, params);
			setData(stats);
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	return {
		load,
		data,
		isLoading,
	};
};
